import { MenuTileProps } from '../Sidebar/MenuTile'

export interface MenuItem extends MenuTileProps {
  dataTest?: string
}

export const baseMenuItems: MenuItem[] = [
  { iconId: 'user', text: 'Mi cuenta', dataTest: 'menu-account', actionType: 'navigation', slug: 'my-account' },
  {
    iconId: 'truck',
    text: 'Mis direcciones',
    dataTest: 'menu-addresses',
    actionType: 'navigation',
    slug: 'mis-direcciones',
  },
  { iconId: 'cart_0', text: 'Mis pedidos', dataTest: 'menu-orders', actionType: 'navigation', slug: 'my-orders' },
  {
    iconId: 'heart_outline',
    text: 'Mis Favoritos',
    dataTest: 'menu-favorites',
    actionType: 'navigation',
    slug: 'mis-favoritos',
  },
]

export const logoutMenuItem: MenuItem = {
  iconId: 'logout',
  text: 'Cerrar Sesión',
  actionType: 'signout',
  dataTest: 'menu-logout',
}

export const menuItems: MenuItem[] = [...baseMenuItems, logoutMenuItem]
