import { MenuTileProps } from '../Sidebar/MenuTile'

export const baseMenuItems: MenuTileProps[] = [
  { iconId: 'home', text: 'Inicio', actionType: 'navigation', slug: '/' },
  { iconId: 'location', text: 'Mi ubicación: $location', actionType: 'location' },
  {
    iconId: 'truck',
    text: 'Seguimiento en línea',
    actionType: 'navigation',
    slug: 'tracking',
  },
  { iconId: 'Promociones', text: 'Promociones', actionType: 'navigation', slug: 'promotion' },
  {
    iconId: 'grid',
    text: 'Categorías',
    actionType: 'category',
    slug: 'mis-favoritos',
  },
]

export const menuItems: MenuTileProps[] = [...baseMenuItems]
