import styled, { keyframes } from 'styled-components'
import { DefaultTheme } from '@ecommerce/shared'

const dotFlashing = (theme: DefaultTheme) => keyframes`
  0% {
    background-color: ${theme.colors.medium};
  }

  50%, 100% {
    background-color: ${theme.colors.medium50};
  }
`

const DotsLoader = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.medium};
  color: ${({ theme }) => theme.colors.medium};
  animation: ${({ theme }) => dotFlashing(theme)} 1s infinite linear alternate;
  animation-delay: 0.5s;

  :before,
  :after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  :before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.medium};
    color: ${({ theme }) => theme.colors.medium};
    animation: ${({ theme }) => dotFlashing(theme)} 1s infinite alternate;
    animation-delay: 0s;
  }

  :after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.medium};
    color: ${({ theme }) => theme.colors.medium};
    animation: ${({ theme }) => dotFlashing(theme)} 1s infinite alternate;
    animation-delay: 1s;
  }
`

export default DotsLoader
