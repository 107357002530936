import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { toCssPrefix, ZIndex } from '@ecommerce/shared'
import { NavBarMenuProps } from './NavBarMenu'
import { NavBarMenuTab } from './NavBarTab'

const { cssPrefix, toPrefix } = toCssPrefix('NavBarMenuDesktop__')

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.brandText.default};
  margin-left: 20px;
  margin-right: 20px;
  .${cssPrefix} {
    &container {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 250px;
      width: 100%;
    }
    &tab {
      margin-right: 50px;
      width: 100px;
      &:last-child {
        margin-right: 0;
      }
      &-anchor {
        span {
          font-size: 13px;
          font-weight: bold;
        }
      }
    }
    &dropdown {
      top: 72px;
      width: 100vw;
      background: ${({ theme }) => theme.colors.medium};
      position: absolute;
      left: 0px;
      z-index: ${ZIndex.low};
      min-height: 40px;
      box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
    }
  }
`

export function NavBarMenuDesktop({ tabs, className }: NavBarMenuProps) {
  const [dropdownContent, setDropdownContent] = useState<React.ReactNode | null>()
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const findInNodeTree = (element: HTMLElement, compareFn: (el: HTMLElement) => boolean, maxDepth = 2): boolean => {
    if (maxDepth <= 0) return false
    if (compareFn(element)) return true
    if (element.parentNode) return findInNodeTree(element.parentNode as HTMLElement, compareFn, maxDepth - 1)
    return false
  }
  return (
    <Wrapper className={className}>
      <div className={toPrefix('container')}>
        {tabs.map(({ isActive, label, children, onClick, iconId }, idx) => (
          <NavBarMenuTab
            iconId={iconId}
            key={`${label}${idx}`}
            label={label}
            isActive={isActive || activeIndex === idx}
            className={`${toPrefix('tab')}`}
            anchorClassName={toPrefix('tab-anchor')}
            iconSize={24}
            onMouseEnter={() => {
              setActiveIndex(idx)
              if (children) {
                setDropdownContent(children)
              }
            }}
            onMouseLeave={(e) => {
              if (findInNodeTree(e.relatedTarget as HTMLElement, (el) => el === dropdownRef.current)) return
              setActiveIndex(null)
              setDropdownContent(null)
            }}
            onClick={onClick}
            activeHighlight="background"
            iconColor="white"
          />
        ))}
      </div>
      {!!dropdownContent && (
        <div
          className={toPrefix('dropdown')}
          ref={dropdownRef}
          onMouseLeave={() => {
            setDropdownContent(null)
            setActiveIndex(null)
          }}
        >
          {dropdownContent}
        </div>
      )}
    </Wrapper>
  )
}
