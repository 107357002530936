import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { toCssPrefix, sendDataToGTM, City, capitalize } from '@ecommerce/shared'
import { Sidebar } from '../Sidebar'
import { menuItems } from './menuItems'
import { MenuTile, MenuTileProps } from '../Sidebar/MenuTile'
import { CategoryTile } from './CategoryTile'
import { FlatLocationProductCategory } from '../../types/PgPages'
import { Icon } from '../Icon/Icon'

const { cssPrefix, toPrefix } = toCssPrefix('MenuMobile__')

const Wrapper = styled.div`
  padding: 38px 24px 38px;
  .${cssPrefix} {
    &content {
      overflow-y: scroll;
      max-height: calc(100vh - 187px);
    }
    &menu {
      margin-bottom: 38px;
    }
    &subtitle {
      display: flex;
      align-items: center;
      font-weight: bold;
      margin-bottom: 14px;
      .icon {
        margin-right: 14px;
      }
    }
  }
`

type Props = {
  onClose: () => void
  onBlur: () => void
  logoUrl?: string
  footerElement?: ReactNode
  onNavigationClick?: (slug: string) => void
  onLocationClick?: () => void
  currentCity?: City
  categories?: FlatLocationProductCategory[]
}

const MenuMobile = ({
  onClose,
  onBlur,
  logoUrl,
  footerElement,
  onNavigationClick,
  onLocationClick,
  currentCity,
  categories,
}: Props) => {
  const [showCategories, setShowCategories] = useState(false)

  const handleTileClick = (item: MenuTileProps) => {
    if (item.actionType === 'location' && onLocationClick) {
      onLocationClick()
    } else if (item.actionType === 'navigation' && item.slug && onNavigationClick) {
      onNavigationClick(item.slug)
    } else if (item.actionType === 'category') {
      setShowCategories(true)
    }
  }

  const formatMenuItemText = (item: MenuTileProps) => {
    if (item.actionType && item.actionType === 'location' && item.text.includes('$location')) {
      const arr = item.text.split('$location')
      return arr.join(capitalize(currentCity ? currentCity.name : '') || '')
    }
    return item.text
  }

  const onNavigate = (slug: string, name: string) => {
    sendDataToGTM({ event: 'categoryClick', categoryName: name })
    navigate(slug.startsWith('/') ? `/${currentCity?.slug}${slug}` : `/${currentCity?.slug}/category/${slug}`)
  }

  return (
    <div>
      <Sidebar onClose={onClose} onBlur={onBlur} logoUrl={logoUrl}>
        <Wrapper>
          <div className={toPrefix('menu')}>
            {menuItems.map((item) => (
              <MenuTile
                key={item.text}
                iconId={item.iconId}
                text={formatMenuItemText(item)}
                onClick={() => handleTileClick(item)}
                actionType={item.actionType}
              />
            ))}
          </div>
          <div className={toPrefix('footer-area')}>{footerElement}</div>
        </Wrapper>
      </Sidebar>
      {showCategories && (
        <Sidebar onClose={onBlur} onBlur={onBlur} backMessage="Menú principal" onBack={setShowCategories}>
          <Wrapper>
            <div className={toPrefix('subtitle')}>
              <Icon iconId="grid" size="25" className="icon" />
              <span>Categorías</span>
            </div>
            <div className={toPrefix('content')}>
              <div className={toPrefix('menu')}>
                {categories &&
                  categories.map((item) => (
                    <CategoryTile
                      key={item.slug}
                      slug={item.slug}
                      image={item.image}
                      mainCategoryName={item.mainCategoryName}
                      name={item.name}
                      onClick={() => onNavigate(item.slug, item.name)}
                    />
                  ))}
              </div>
            </div>
          </Wrapper>
        </Sidebar>
      )}
    </div>
  )
}

export default MenuMobile
