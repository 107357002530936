import * as React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { breakpoints, useLocation } from '@ecommerce/shared'
import { Icon } from '../Icon/Icon'

const InfoCart = styled.div<{ showRecycled?: boolean }>`
  display: ${(props) => (props.showRecycled ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 114px;
  padding: 9px 6px;
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 13px;

  svg {
    object-fit: contain;
  }

  @media (${breakpoints.phoneLandscape.min}) {
    width: 336px;
  }
`

const RememberPackaging = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 12px 10px 6px;
  width: 100%;
  height: 33px;
`

const Remember = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-around;

  span {
    height: 12px;
    padding-left: 4px;
    font-size: 12px;
    font-weight: bold;
    color: #12c269;
  }
  @media (${breakpoints.phoneLandscape.min}) {
    width: 90%;
    span {
      height: 15px;
      font-size: 15px;
    }
  }
`

const RememberText = styled.span`
  height: 40px;
  font-size: 13px;
  text-align: left;
  color: #808fa9;
  padding: 0 5px;
  @media (${breakpoints.phoneLandscape.min}) {
    span {
      width: 300px;
      font-size: 11px;
    }
  }
`

const RememberButton = styled(motion.div)`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
`

const ButtonClose = styled(motion.div)`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  width: 24px;
  height: 24px;
`

interface Props {
  showRecycled: boolean
  onShowRecycled: Function
}

const ProductCartInfo = (props: Props) => {
  const { showRecycled, onShowRecycled } = props

  const { isBolivia } = useLocation()
  const textByCountry = (clText: string, boText: string) => (isBolivia() ? boText : clText)

  const rememberText = ` ${textByCountry('Producto NO incluye precio por envase. ', '')}Debes tener ${textByCountry(
    'un envase retornable vacío',
    'una botella retornable vacía',
  )} para hacer el intercambio con el repartidor. `

  return (
    <InfoCart showRecycled={showRecycled}>
      <RememberPackaging>
        <Remember>
          <RememberButton>
            <Icon iconId="info_outline" color="#12c269" />
          </RememberButton>
          <span>
            Recuerda tu
            {textByCountry(' envase ', ' botella ')}
            retornable
          </span>
        </Remember>

        <ButtonClose whileTap={{ scale: 0.9 }} onClick={() => onShowRecycled()}>
          <Icon iconId="close" width="18" height="18" color="rgb(95,111,134, 0.75)" />
        </ButtonClose>
      </RememberPackaging>
      <RememberText>{rememberText}</RememberText>
    </InfoCart>
  )
}

export default ProductCartInfo
