/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect, useMemo, HTMLAttributes } from 'react'
import { Helmet } from 'react-helmet'
import { navigate } from 'gatsby'
import loadable from '@loadable/component'
import {
  ZIndex,
  useAuth,
  capitalize,
  useResolution,
  City,
  getAuth,
  sendDataToGTM,
  useLocation,
  AuthFormType,
  AuthWidget,
  useShoppingCart,
} from '@ecommerce/shared'
import styled from 'styled-components'
import { InstantSearch } from 'react-instantsearch-dom'
import algoliaSearch from 'algoliasearch/lite'
import { ParsedQs } from 'qs'
import { Footer } from '../Footer'
import secrets from '../../config/secrets'
import { PgPageProps, FlatLocationProductCategory } from '../../types/PgPages'
import withURLSync from '../../utils/URLSync'
import { useUserLoginHandler } from '../../hooks/useUserLoginHandler'
import useSession from '../../hooks/useSession'
import useAuthWidget from '../../hooks/useAuthWidget'
import { MainNavbar } from '../NavBar/MainNavbar'
import { useMobileNavbarMenu } from '../../hooks/useMobileNavbarMenu'
import { MomentHeader } from '../NavBar/MomentHeader'
import ShoppingCart from '../ShoppingCart'
import MyAccountSidebar from '../MyAccountSidebar/MyAccountSidebar'
import LocationSelector from '../LocationSelector'

const searchClient = algoliaSearch(secrets.ALGOLIA_APP_ID, secrets.ALGOLIA_API_KEY)

const loadableOptions = {
  ssr: false,
}

const AgeModal = loadable(() => import('../AgeDisclosureModal'), loadableOptions)
const ConfirmationAlert = loadable(() => import('../ConfirmationAlert'), loadableOptions)
const ModalBackground = loadable(() => import('@ecommerce/shared'), {
  resolveComponent: (components) => components.ModalBackground,
  ...loadableOptions,
})

const Container = styled.div`
  margin: 72px 0 0;
  width: 100%;
  z-index: ${ZIndex.default};
`

const NavBarWrapper = styled.div`
  top: 0;
  width: 100vw;
  z-index: ${ZIndex.medium};
`

const Wrapper = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 1.44;
  letter-spacing: -0.2px;
`

export interface CustomNavbarProps extends HTMLAttributes<HTMLDivElement> {
  pathName?: string
  onOpenCart?: () => void
  onUserClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onLocationClick?: () => void
  currentCity?: string
}

interface Props {
  children: React.ReactNode
  title: string
  id?: string
  pathName?: string
  currentCity: City
  location?: PgPageProps['location']
  navbar?: (props: CustomNavbarProps) => React.ReactNode
  searchState?: string
  createURL?: (...args: Record<string, unknown>[]) => string
  onSearchStateChange?: (args: ParsedQs) => unknown
  navbarType?: string
  navbarTitlte?: string
  openCart?: boolean
  onOpenCart?: () => boolean
  categories?: FlatLocationProductCategory[]
  useAlgoliaSearch?: boolean
  defaultSearchValue?: string
  disableControls?: boolean
}

const imgUrlCL =
  'https://images.ctfassets.net/16npdkkoi5mj/UQAY5ote3JybB3dWTDfKp/743c2ca9fdeb79c72374cecb6bf557a8/Logo_MCC_Chilea_2.svg'

const imgUrlBO =
  'https://images.ctfassets.net/5czn5snkqxg9/6My5qEokCQ4vvhFFU6m4W0/c2a0b1dc859f1acacb58cf5a8af769af/Logo_Micoca-cola_Bolivia.svg'

const Layout = (props: Props) => {
  const { children, categories, title, id, pathName = '', currentCity, location, openCart, onOpenCart } = props

  const { isDesktop } = useResolution()
  const {
    state: { firstName, lastName },
  } = useAuth()
  const isAuth = getAuth()

  const params = location ? new URLSearchParams(location.search) : null
  const askUrl = params ? !!params.get('ask_auth') : null
  const showCartUrl = params ? params.has('show_cart') : null
  const createAccountPram = params ? !!params.get('create_account') : null

  const [isOpenCart, setIsOpenCart] = useState(showCartUrl ?? openCart ?? false)
  useEffect(() => {
    if (onOpenCart) {
      onOpenCart()
    }
  }, [isOpenCart])
  useEffect(() => {
    setIsOpenCart(openCart ?? isOpenCart)
  }, [openCart])
  const [isOpenMyAccount, setIsOpenMyAccount] = useState(false)
  const [isOpenSignoutModal, setIsOpenSignoutModal] = useState(false)
  const [isOpenLoaction, setIsOpenLocation] = useState(false)

  const { UserLoginHandlerAlertComponent, userLoginCartHandler } = useUserLoginHandler()
  const { resetAppState } = useSession()

  const onFinishedSignIn = async () => {
    await navigate(`${window.location.pathname}?show_greeting=true`)
    onCloseModal()
  }

  const authFormsProps = {
    signin: {
      userLoginCartHandler,
      title: 'Iniciar Sesión',
      onFinished: () => onFinishedSignIn(),
    },
    guest: {
      title: 'Continuar como invitado',
      onFinished: () => onCloseModal(),
    },
    signup: {
      title: 'Crear Cuenta',
      onFinished: () => sendDataToGTM({ event: 'newAccount' }),
    },
  }

  const { showWidget, closeWidget, state: authWidgetState, getProps } = useAuthWidget()

  function onCloseModal() {
    closeWidget()
  }
  function showAuthWidget(type: AuthFormType) {
    showWidget(type)
  }
  const onSignOut = async () => {
    setIsOpenSignoutModal(false)
    setIsOpenMyAccount(false)

    await resetAppState(`/${currentCity.slug}`)
  }

  useEffect(() => {
    if (askUrl) return showAuthWidget(AuthFormType.SIGNIN)

    if (createAccountPram) return showAuthWidget(AuthFormType.SIGNUP)
  }, [])

  const { textByCountry, isBolivia } = useLocation()
  const isBO = isBolivia()

  const navbarMenu = useMobileNavbarMenu({
    categories,
    citySlug: currentCity.slug,
    isMoment: props.navbarType === 'moment' && !!props.navbarTitlte && !isDesktop,
    disableControls: props.disableControls,
  })

  const {
    state: { globalQuantity },
  } = useShoppingCart()

  const getSidebarFooterElement = (phone: string, mail: string) => (
    <Wrapper>
      <span>
        ¿Necesitas Ayuda?
        <br />
        {phone}
        <br />
        {mail}
      </span>
    </Wrapper>
  )

  const sidebarFooterElement = getSidebarFooterElement(
    textByCountry('800 72 12 12', '800 10 26 53'),
    textByCountry('contacto@embonor.cl', 'contacto@micoca-cola.bo'),
  )

  const searchState = useMemo(
    () => (props.searchState && Object.keys(props.searchState).length ? props.searchState : {}),
    [props.searchState],
  )

  return (
    <>
      {authWidgetState.showWidget && authWidgetState.type ? (
        <AuthWidget
          showWidget={showAuthWidget}
          closeWidget={closeWidget}
          title={authFormsProps[authWidgetState.type].title}
          formSettings={getProps(authWidgetState.type, authFormsProps)}
          onClose={closeWidget}
        />
      ) : null}
      <AgeModal hideModal={isBO} />
      <InstantSearch
        indexName={secrets.ALGOLIA_INDEX_NAME}
        searchClient={searchClient}
        searchState={searchState}
        createURL={props.createURL}
        onSearchStateChange={props.onSearchStateChange}
      >
        <Container id={id}>
          {props.navbar ? (
            <NavBarWrapper>
              {props.navbar({
                pathName,
                onOpenCart: () => setIsOpenCart(true),
                onUserClick: () => {
                  if (!isAuth) {
                    if (authWidgetState.showWidget) return closeWidget()
                    return showAuthWidget(AuthFormType.SIGNIN)
                  }
                  setIsOpenMyAccount(!isOpenMyAccount)
                },
                onLocationClick: () => setIsOpenLocation(true),
                currentCity: currentCity ? capitalize(currentCity.name) : '',
              })}
            </NavBarWrapper>
          ) : (
            <MainNavbar
              logoUrl={
                isBO
                  ? 'https://images.ctfassets.net/5czn5snkqxg9/6PESRN9i2N3aVUjITRalJS/786ba991d56a889d19c0379d7e051bdc/logo.svg'
                  : 'https://images.ctfassets.net/16npdkkoi5mj/7E46MY9iDUr4A5RkUAwsq5/8dd893fc8bf06d7c7bf048751cc74c35/160x50mcc__1_.svg'
              }
              imgUrl={isBO ? imgUrlBO : imgUrlCL}
              categories={categories}
              onLocationClick={() => setIsOpenLocation(true)}
              onNavigationClick={(slug) => navigate(`/${currentCity.slug}/${slug}`)}
              disableControls={props.disableControls}
              defaultSearchValue={props.defaultSearchValue}
              onSearchKeydown={(e) => {
                e.stopPropagation()
                closeWidget()
                setIsOpenCart(false)
                const query = e.currentTarget.value ?? (e.target as HTMLInputElement).value
                if (e.key === 'Enter' && !props.useAlgoliaSearch)
                  navigate(`/${currentCity.slug}/search?query=${encodeURI(query)}`, { state: { query } })
              }}
              onUserClick={() => {
                if (!isAuth) {
                  if (authWidgetState.showWidget) return closeWidget()
                  return showAuthWidget(AuthFormType.SIGNIN)
                }
                setIsOpenMyAccount(!isOpenMyAccount)
              }}
              onCartClick={() => {
                closeWidget()
                setIsOpenMyAccount(false)
                setIsOpenCart(!isOpenCart)
              }}
              onLogoClick={() => {
                navigate(`/${currentCity.slug}`)
              }}
              onLocationSelectorClick={() => {
                setIsOpenLocation(true)
              }}
              tabs={navbarMenu}
              cityName={currentCity.name}
              cartItemsQuantity={globalQuantity}
              leftSection={
                props.navbarTitlte && props.navbarType === 'moment' ? (
                  <MomentHeader>
                    <span>{props.navbarTitlte}</span>
                  </MomentHeader>
                ) : undefined
              }
              useAlgoliaSearch={props.useAlgoliaSearch}
              userName={firstName ?? undefined}
              footerElement={sidebarFooterElement}
              currentCity={currentCity}
            />
          )}
          <Helmet>
            <title>{title}</title>
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
          </Helmet>
          {children}
          <Footer />
        </Container>
        {isOpenCart && (
          <ModalBackground>
            <ShoppingCart isOpenCart={isOpenCart} onCloseCart={() => setIsOpenCart(false)} currentCity={currentCity} />
          </ModalBackground>
        )}
      </InstantSearch>
      {isOpenMyAccount && isAuth && (
        <MyAccountSidebar
          customerName={`${capitalize(firstName)} ${capitalize(lastName)}`}
          logoUrl={isBO ? imgUrlBO : imgUrlCL}
          onClose={() => setIsOpenMyAccount(false)}
          onBlur={() => setIsOpenMyAccount(false)}
          onSignOut={() => setIsOpenSignoutModal(true)}
          onAccountClick={() => navigate(currentCity && currentCity.slug ? `/${currentCity.slug}/my-account` : '/')}
          onLocationClick={() => setIsOpenLocation(true)}
          onNavigationClick={(slug) => navigate(`/${currentCity.slug}/${slug}`)}
          currentCity={currentCity ? capitalize(currentCity.name) : ''}
          footerElement={sidebarFooterElement}
        />
      )}
      {isOpenSignoutModal && isAuth && (
        <ConfirmationAlert
          onClose={() => setIsOpenSignoutModal(false)}
          onBlur={() => setIsOpenSignoutModal(false)}
          onConfirm={onSignOut}
          confirmButtonText="Cerrar sesión"
          text="¿Estás seguro de querer cerrar tu sesión?"
        />
      )}
      {isOpenLoaction && <LocationSelector onClose={() => setIsOpenLocation(false)} requireConfirmation />}
      <UserLoginHandlerAlertComponent onFinished={onFinishedSignIn} />
    </>
  )
}

export default withURLSync<Props>(Layout)
