import React, { useState, useMemo, useEffect } from 'react'
import loadable from '@loadable/component'
import styled, { DefaultTheme } from 'styled-components'
import { motion } from 'framer-motion'
import {
  Image,
  breakpoints,
  useShoppingCart,
  toNumber,
  Product,
  ModalBackground,
  useLocation,
  useDisableAddProductButton,
  sendAddProductToGTM,
  sendRemoveProductToGTM,
  PromotionDetail,
} from '@ecommerce/shared'
import DotsLoader from '../DotsLoader'
import { IconAdd, IconSubtract, IconTrash, IconInfo } from '../Icons'
import ProductCartInfo from './ProductCartInfo'
import { useCartStockErrorHandler } from '../../utils/errors'

const ConfirmModal = loadable(() => import('./ConfirmModal'))

const ImageCart = styled.div`
  width: 77px;
  height: 82px;
  padding-right: 8px;
  img {
    padding-top: 5px;
    border-radius: 8px;
  }
`
const WrapperDetails = styled(motion.div)<{ showRecycled: boolean }>`
  width: 100%;
  height: 120px;
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 13px;
  box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
  padding: 15px 7px 6px 10px;
`
const DetailCart = styled(motion.div)<{ showRecycled: boolean }>`
  display: ${(props) => (props.showRecycled ? 'none' : 'flex')};
  align-items: center;
  width: 90%;
  border-radius: 8px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (${breakpoints.phoneLandscape.min}) {
    padding-right: 6px;
    width: 100%;
  }
`

const DescriptionCart = styled.div<{ recycled: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 48%;
  height: ${(props) => (props.recycled ? 'auto' : '80px')};

  > * {
    display: flex;
    justify-content: flex-start;
    padding: 2px;
  }

  span {
    color: #5f6f86;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .product-cart-title {
    display: block;
    font-size: 11px;
    height: 30px;
    overflow: hidden;
    padding: 0;
    &-name {
      white-space: normal;
      line-clamp: 2;
    }
    &-name,
    &-description {
      font-size: 12px;
      font-weight: bold;
    }
    &-percentDcto {
      color: ${({ theme }) => theme.colors.success};
      font-size: 11px;
      font-weight: normal;
      padding: 0;
    }
    &-promotion-label {
      color: ${({ theme }) => theme.colors.success};
      font-size: 11px;
      font-weight: normal;
      padding: 0;
      font-weight: bold;
    }
    &-total {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .product-cart-button {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    &-icon-subtract,
    &-icon-add {
      width: 21px;
      height: 21px;
      border-radius: 50%;
      box-shadow: 1px 1px 1px 0 rgba(209, 209, 209, 0.5);
      border: solid 1px #f1f1f1;
    }
  }
`

const ButtonIcon = styled(motion.div)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  -webkit-tap-highlight-color: transparent;
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.medium : theme.colors.white)};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  fill: ${({ disabled, theme }) => (disabled ? theme.colors.dark : 'auto')};

  .product-cart-button {
    &-icon-subtract,
    &-icon-add {
      margin: 0 auto;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      outline: none;
      box-shadow: 1px 1px 1px 0 rgba(209, 209, 209, 0.5);
      background-color: #ffffff;
    }
  }
`

const PriceCart = styled.div<{ discount: boolean }>`
  width: 27%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: ${(props) => (props.discount ? '80px' : '76px')};

  svg {
    cursor: pointer;
    :hover {
      opacity: 0.6;
    }
  }

  .product-cart-gift-label {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.dark};
    display: block;
    position: relative;
    margin-top: auto;
  }

  .product-cart-price {
    &-normal {
      font-size: 13px;
      color: #6a7a92;
      text-decoration: ${(props) => (props.discount ? 'line-through' : 'none')};
    }
    &-discount {
      font-size: 16px;
      color: #5f6f86;
      font-weight: bold;
      height: 19px;
      white-space: nowrap;
    }
    &-loading {
      width: 30px;
      height: 19px;
      display: flex;
      align-items: flex-end;
    }
  }
`

const ButtonTrash = styled(motion.div)`
  background-color: transparent;
  outline-color: transparent;
  outline-style: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  &:focus {
    outline: 0;
    border: none;
    outline-style: none;
    -moz-outline-style: none;
  }
  svg {
    fill: ${({ theme }) => theme.colors.error};
    object-fit: contain;
  }
`

const infoBadgeColor = () => ({
  theme,
  ...props
}: {
  isRecyclable?: boolean
  isCustomizable?: boolean
  presale?: boolean
  theme: DefaultTheme
}) => {
  if (props.presale) {
    return theme.colors.gold
  }

  if (props.isRecyclable) {
    return theme.colors.success
  }

  if (props.isCustomizable) {
    return theme.colors.blue
  }

  return 'inherit'
}

const ProductInfo = styled(motion.div)<{ showMessage: boolean; isRecyclable?: boolean; presale?: boolean }>`
  height: 15px;
  min-width: 170px;
  padding-left: 5px;
  display: ${({ showMessage }) => (showMessage ? 'flex' : 'none')};
  cursor: ${({ isRecyclable }) => (isRecyclable ? 'pointer' : 'auto')};
  align-items: center;

  span {
    width: auto;
    height: 13px;
    font-size: 11px;
    font-weight: bold;
    color: ${infoBadgeColor()};
    cursor: pointer;
  }

  .info-button {
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    margin-right: 3px;
    background: none;
    border: none;
    padding: 0;

    svg {
      width: 15px;
      height: 15px;
      opacity: 0.75;
      fill: ${infoBadgeColor()};
      object-fit: contain;
    }
  }
`

const ShowRemember = styled(motion.div)<{ showRecycled: boolean }>`
  display: ${(props) => (props.showRecycled ? 'flex' : 'none')};
`

const Wrapper = styled.div`
  width: 90%;
`

interface ProductCart extends Product {
  quantity: number
}

interface Props {
  product: ProductCart
  presale?: boolean
  promotion?: PromotionDetail
  setLoadingPrice?: (b: boolean) => void
  loadingPrice?: boolean
  isGift?: boolean
  giftQuantity?: number
}

const ProductCart = (props: Props) => {
  const { product, presale, promotion, setLoadingPrice, loadingPrice, isGift, giftQuantity } = props
  const {
    skuCode,
    image,
    title,
    discount = 0,
    price,
    rawPrice = 0,
    recyclable = false,
    unavailable,
    quantity,
    isBundle,
    hasDiscount,
  } = product

  const discountAmount = promotion?.discountAmount ?? 0

  const [showRecycled, setShowRecycled] = useState(false)
  const [firestoreUpdateTimeout, setFirestoreUpdateTimeout] = useState(0)
  const [productQuantity, setProductQuantity] = useState(quantity)
  const { addProduct, subtractProduct, removeProduct, state } = useShoppingCart()
  const [isOpenModal, setIsOpenModal] = useState(false)

  const { toCurrency, textByCountry, isBolivia } = useLocation()

  const stockErrorHandler = useCartStockErrorHandler()

  useEffect(() => {
    if (setLoadingPrice) setLoadingPrice(false)
  }, [state.promotionsUpdatedAt])

  const updateProduct = () => {
    if (productQuantity - quantity >= 0 && !isGift) {
      sendAddProductToGTM(product, isBolivia())
      return addProduct(
        {
          product: { skuCode, image, title, rawPrice, price, discount, recyclable, unavailable, hasDiscount },
          quantity: productQuantity - quantity,
        },
        stockErrorHandler,
      )
    }
    sendRemoveProductToGTM([{ ...product, quantity: quantity - productQuantity }], isBolivia())
    return subtractProduct(skuCode, quantity - productQuantity)
  }

  const resetFirebaseUpdateTimeout = () => {
    clearTimeout(firestoreUpdateTimeout)
    setFirestoreUpdateTimeout(setTimeout(updateProduct, 500))
  }

  useEffect(() => {
    resetFirebaseUpdateTimeout()
  }, [productQuantity])

  const onIncrease = () => {
    if (setLoadingPrice) setLoadingPrice(true)
    setProductQuantity(productQuantity + 1)
  }

  const onDecrease = () => {
    if (setLoadingPrice) setLoadingPrice(true)
    setProductQuantity(productQuantity - 1)
  }

  function onDeleteAll() {
    sendRemoveProductToGTM([product], isBolivia())
    return removeProduct(skuCode)
  }

  function onShowRecycled() {
    setShowRecycled(false)
  }

  const disabledAddButton = useDisableAddProductButton({ skuCode: product.skuCode, quantity })
  const isDisabled = !!product.labelUrl || presale || disabledAddButton || isGift

  const infoBadgeText = useMemo(() => {
    if (recyclable) return ` Recuerda tu ${textByCountry(' envase', ' botella')}`
    if (presale) return `Preventa (Entrega el 03/12)`
    if (typeof product.labelUrl === 'string') return `Producto personalizado`

    return null
  }, [recyclable, product?.presale, product?.labelUrl])

  return (
    <Wrapper>
      {isOpenModal && (
        <ModalBackground>
          <ConfirmModal
            closeModal={() => setIsOpenModal(false)}
            onDeleteAll={onDeleteAll}
            image={image}
            message={title}
          />
        </ModalBackground>
      )}
      <WrapperDetails showRecycled={showRecycled}>
        <DetailCart
          key={skuCode}
          animate={{ scale: showRecycled ? 0 : 1 }}
          transition={{ duration: 0.5 }}
          showRecycled={showRecycled}
        >
          <ImageCart>
            <Image preload={false} src={image} alt="detail-cart" />
          </ImageCart>

          <DescriptionCart recycled={recyclable}>
            <div className="product-cart-title">
              <span className="product-cart-title-name">{title}</span>
            </div>

            {discount && discount > 0 ? (
              <span className="product-cart-title-percentDcto">{`${toNumber(discount)}% Dcto.`}</span>
            ) : (
              ''
            )}

            {promotion && promotion.labels ? (
              <span className="product-cart-title-promotion-label">{promotion.labels[0]}</span>
            ) : (
              ''
            )}

            <div className="product-cart-button">
              <ButtonIcon
                disabled={isGift}
                data-test="product-cart-decrease"
                className="product-cart-button-icon-subtract"
                whileTap={{ scale: 0.75 }}
                onClick={() => {
                  if (isGift) return null
                  if (productQuantity === 1) return setIsOpenModal(true)

                  return onDecrease()
                }}
              >
                <IconSubtract />
              </ButtonIcon>
              <span className="product-cart-title-total">{toNumber(giftQuantity || productQuantity)}</span>
              <ButtonIcon
                data-test="product-cart-increase"
                className="product-cart-button-icon-add"
                whileTap={isDisabled ? {} : { scale: 0.65 }}
                onClick={isDisabled ? () => null : onIncrease}
                disabled={isDisabled}
              >
                <IconAdd />
              </ButtonIcon>
            </div>
          </DescriptionCart>

          <PriceCart className="product-cart-price" discount={(discount && discount > 0) || discountAmount > 0}>
            {!isGift ? (
              <>
                <ButtonTrash
                  data-testid="trash-product"
                  whileTap={{ scale: 0.75 }}
                  onClick={() => setIsOpenModal(true)}
                >
                  <IconTrash />
                </ButtonTrash>
                {(discount && discount > 0) || discountAmount > 0 ? (
                  <span data-testid="product-price" className="product-cart-price-normal">
                    {toCurrency(rawPrice * quantity)}
                  </span>
                ) : null}
                {!loadingPrice || !promotion ? (
                  <span data-testid="product-discount" className="product-cart-price-discount">
                    {toCurrency(price * quantity - discountAmount)}
                  </span>
                ) : (
                  <div className="product-cart-price-loading">
                    <DotsLoader />
                  </div>
                )}
              </>
            ) : (
              <span className="product-cart-gift-label">Regalo</span>
            )}
          </PriceCart>
        </DetailCart>
        <ProductInfo
          showMessage={(recyclable && !isBundle) || !!product.labelUrl || !!presale}
          isRecyclable={recyclable}
          animate={{ scale: showRecycled ? 0 : 1 }}
          transition={{ duration: 0.5 }}
          onClick={() => (recyclable ? setShowRecycled(true) : null)}
          presale={product?.presale}
        >
          <button type="button" className="info-button">
            <IconInfo />
          </button>
          <span>{infoBadgeText}</span>
        </ProductInfo>
      </WrapperDetails>

      <ShowRemember
        animate={{ scale: showRecycled ? 1 : 0 }}
        transition={{ duration: 0.5 }}
        showRecycled={showRecycled}
      >
        <ProductCartInfo showRecycled={showRecycled} onShowRecycled={onShowRecycled} />
      </ShowRemember>
    </Wrapper>
  )
}

export default ProductCart
