import React from 'react'
import styled from 'styled-components'
import { breakpoints, Image, truncateString } from '@ecommerce/shared'
import { motion } from 'framer-motion'
import { Icon } from '../Icon/Icon'

const ContainerConfirm = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  height: 318px;
  top: 201px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.white};
  position: relative;
  margin-left: auto;
  margin-right: auto;

  span {
    font-stretch: normal;
    font-style: normal;
  }
  @media (${breakpoints.phoneLandscape.min}) {
    width: 340px;
    right: 15px;
    position: absolute;
  }
`

const BoxClose = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 87%;
  height: 24px;
  margin-top: 18px;
`

const BoxText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 300px;
  border-bottom: solid 1px ${(props) => props.theme.colors.black25};
  padding-bottom: 18px;
  margin-top: 20px;

  span {
    width: 226px;
    height: 54px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.29;
    letter-spacing: normal;
    color: ${(props) => props.theme.colors.black};
  }
`

const ButtonContinue = styled(motion.button)`
  width: 260px;
  height: 50px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.primary};
  border: none;
  outline: none;
  margin-top: 20px;

  span {
    font-size: 17px;
    text-align: center;
    color: ${(props) => props.theme.colors.white};
  }
`

const ButtonClear = styled(motion.button)`
  width: 260px;
  height: 50px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.white};
  border: solid 1px ${(props) => props.theme.colors.primary};
  outline: none;
  margin-top: 20px;

  span {
    font-size: 17px;
    text-align: center;
    color: #f70a00;
  }
`

const ButtonClose = styled(motion.button)`
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  width: 34px;
  height: 24px;
  -webkit-tap-highlight-color: transparent;
`

const ImageCart = styled.div<{ show: boolean }>`
  width: 25%;
  img {
    padding: 4px 6px 0 0;
  }

  display: ${(props) => (props.show ? 'block' : 'none')};
`

interface Props {
  closeModal: Function
  onDeleteAll: Function
  image: string | null
  message: string
}

const ConfirmModal = (props: Props) => {
  const { closeModal, onDeleteAll, image, message } = props

  const messageConfirm = truncateString(message, 30) || 'todos los productos'
  const deleteButton = message ? 'Eliminar del' : 'Vaciar'

  return (
    <ContainerConfirm>
      <BoxClose>
        <div />
        <ButtonClose
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            closeModal()
          }}
        >
          <Icon iconId="close" sizeWidth="24" sizeHeight="24" fillColor="black50" />
        </ButtonClose>
      </BoxClose>
      <BoxText>
        <ImageCart show={!!image}>
          <Image src={image} alt="cart-image" className="confirm-modal-shopping-cart-image" />
        </ImageCart>
        {!image ? <Icon iconId="trash" sizeWidth="70" sizeHeight="70" fillColor="tertiary" /> : ''}
        <span>{`¿Quieres eliminar ${messageConfirm} de tu carro?`}</span>
      </BoxText>
      <ButtonContinue
        whileTap={{ scale: 0.95 }}
        onClick={() => {
          closeModal()
        }}
      >
        <span>
          <b>Continuar con la compra</b>
        </span>
      </ButtonContinue>
      <ButtonClear
        whileTap={{ scale: 0.95 }}
        onClick={() => {
          onDeleteAll()
          closeModal()
        }}
      >
        <span>
          <b>{`${deleteButton} Carro`}</b>
        </span>
      </ButtonClear>
    </ContainerConfirm>
  )
}

export default ConfirmModal
