import React, { useRef } from 'react'
import styled from 'styled-components'
import { debounce, CartStockErrorHandler, CLRequestError, showToast } from '@ecommerce/shared'
import { sendStockReports } from './sentry'

export const extractCLayerErrorMessagesArray = (error: CLRequestError) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.errors &&
    error.response.data.errors.length
  ) {
    return error.response.data.errors.map((err) => err.detail)
  }
  if (error.message) return [error.message]
  return [error]
}

const NotificationBody = styled.div`
  .NotificationBody__title {
    font-weight: bold;
    margin-bottom: 8px;
  }
`
type NotificationProps = {
  title?: string
  content: string
}

export const ErrorMessage = ({ title, content }: NotificationProps) => (
  <NotificationBody>
    <div className="NotificationBody__title">{title}</div>
    <div>
      <span>{content}</span>
    </div>
  </NotificationBody>
)

export const useCartStockErrorHandler = () => {
  const notifyStockError = useRef(
    debounce(
      () => {
        showToast({
          title: 'Sin stock',
          content: 'Oh no, ¡producto sin stock! Intenta más tarde por favor.',
          type: 'error',
        })
      },
      6000,
      { isImmediate: true },
    ),
  ).current
  const errorHandler: CartStockErrorHandler = async ({ product }) => {
    notifyStockError()
    sendStockReports({ products: [product], section: 'StockNotification' })
  }
  return errorHandler
}
