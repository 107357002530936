import styled from 'styled-components'
import { skeletonAnimationStyles } from '@ecommerce/shared'

type SkeletonItemProps = {
  width?: string
  height?: number
  margin?: string
  animate?: boolean
}

const SkeletonItem = styled.div<SkeletonItemProps>`
  background: ${({ theme }) => theme.colors.medium};
  width: ${({ width }) => (width ? `${width}` : '100%')};
  min-width: ${({ width }) => (width ? `${width}` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '40px')};
  margin: ${({ margin }) => margin ?? '0'};
  opacity: 1;
  border-radius: ${({ theme }) => theme.borderRadius};
  opacity: 1;
  ${({ animate }) => (animate ? skeletonAnimationStyles : '')}
`

export default SkeletonItem
