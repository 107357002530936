import React, { useState, useEffect, useMemo, memo } from 'react'
import styled from 'styled-components'
import { log, ProductCart as ProductCartType } from '@ecommerce/shared'
import { getNavigatableProductsBySkus } from '../../utils/algolia'
import ProductCart from './ProductCart'
import ProductCartSkeleton from './ProductCartSkeleton'

type Props = {
  items: Array<{
    sku: number
    quantity: number
  }>
  slugLocation: string
  loading?: boolean
  onFetchProducts?: (products: ProductCartType[]) => void
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const GiftProduct = memo(({ items, slugLocation, onFetchProducts, loading }: Props) => {
  const [products, setProducts] = useState<ProductCart[]>()
  const [isLoadingProducts, setIsLoadingProducts] = useState(loading)

  const skus = useMemo(() => items?.map((item) => item.sku.toString()), [items])

  const getDiscountItem = (sku: number) => {
    return items.find((item) => item.sku === sku)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingProducts(true)
        const algoliaProducts = await getNavigatableProductsBySkus(
          slugLocation,
          skus,
          {},
          slugLocation,
          undefined,
          undefined,
          true,
        )

        setProducts(algoliaProducts)
      } catch (error) {
        log.error(error)
      } finally {
        setIsLoadingProducts(false)
      }
    }

    if (skus.length && !loading) fetchData()
  }, [])

  useEffect(() => {
    if (products?.length && onFetchProducts && !loading) {
      const transformedProducts = products.map<ProductCartType>((product) => {
        const discountItem = getDiscountItem(Number(product.skuCode))
        return {
          ...product,
          quantity: discountItem?.quantity || 0,
          isGift: true,
          discountQuantity: discountItem?.quantity || 0,
          discount: product?.discount || 0,
        }
      })

      onFetchProducts(transformedProducts)
    }
  }, [products, onFetchProducts, loading])

  return (
    <Wrapper>
      {isLoadingProducts || loading || !products?.length ? (
        <>
          {Array(items?.length)
            .fill(0)
            .map((_, i) => (
              <ProductCartSkeleton key={i} />
            ))}
        </>
      ) : (
        <>
          {products.map((product) => (
            <ProductCart
              isGift
              giftQuantity={getDiscountItem(Number(product?.skuCode))?.quantity || 0}
              key={product.skuCode}
              product={product}
            />
          ))}
        </>
      )}
    </Wrapper>
  )
})

export default GiftProduct
