import React, { useEffect, useState, HTMLAttributes } from 'react'
import styled from 'styled-components'
import { Image, ModalBackground } from '@ecommerce/shared'
import { Icon } from '../Icon/Icon'

const cssPrefix = `Sidebar`

const SidebarWrapper = styled.div<{ isRight?: boolean; isLeft?: boolean }>`
  width: 325px;
  height: 100vh;
  color: ${({ theme }) => theme.colors.blackHigh};
  background: ${(props) => props.theme.colors.white};
  transition: transform 0.5s ease;
  transform: ${(props) => (props.isRight ? 'translateX(100vw)' : 'translateX(-325px)')};
  &.${cssPrefix}active {
    transform: ${(props) => (props.isRight ? 'translateX(calc(100vw - 325px))' : 'translateX(0)')};
  }
  .${cssPrefix} {
    &top-bar {
      width: 100%;
      height: 72px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 16px;
      box-shadow: 0px 4px 8px rgba(3, 15, 28, 0.15);
      .back {
        font-weight: bold;
        display: flex;
        align-items: center;
        svg {
          margin-right: 8px;
        }
      }
      &-logo-container {
        width: 145px;
      }
      &-close-icon {
        cursor: pointer;
        margin-right: 5px;
      }
      button {
        all: unset;
      }
    }
    &body {
      width: 100%;
      height: 100%;
    }
  }
`

const addPrefix = (className: string) => `${cssPrefix}${className}`

export interface SidebarProps {
  onClose: () => void
  onBlur?: () => void
  logoUrl?: string
  isRight?: boolean
  isLeft?: boolean
  onBack?: (state: boolean) => void
  backMessage?: string
}

export const Sidebar = (props: SidebarProps & HTMLAttributes<HTMLDivElement>) => {
  const [sidebarClassNames, setSidebarClassNames] = useState('')
  useEffect(() => {
    setSidebarClassNames(`${cssPrefix}active`)
    return function cleanup() {
      setSidebarClassNames('')
    }
  }, [])

  const closeSidebar = (onFunction: () => void) => {
    setSidebarClassNames('')
    setTimeout(() => onFunction(), 500)
  }

  return (
    <ModalBackground onBlur={() => closeSidebar(() => props.onBlur && props.onBlur())}>
      <SidebarWrapper className={sidebarClassNames} {...props}>
        <div className={addPrefix('top-bar')}>
          {props.backMessage ? (
            <button
              type="button"
              className="back"
              onClick={() => closeSidebar(() => props.onBack && props.onBack(false))}
            >
              <Icon iconId="arrow_left" size="24" />
              {props.backMessage}
            </button>
          ) : (
            <div className={addPrefix('top-bar-logo-container')}>
              <Image
                preload={false}
                src={
                  props.logoUrl ||
                  'https://images.ctfassets.net/16npdkkoi5mj/4EAocVvRjUs3wHYN7PiSDB/f89865bb82569f8a649d1c07fbfbfbd5/CCO_-_BLANCO_-_SVG.svg'
                }
                alt="logo"
              />
            </div>
          )}
          <Icon
            iconId="close"
            size="24"
            data-test="close-icon"
            className={addPrefix('top-bar-close-icon')}
            onClick={() => closeSidebar(props.onClose)}
          />
        </div>
        <div className={addPrefix('body')}>{props.children}</div>
      </SidebarWrapper>
    </ModalBackground>
  )
}
