import React, { useRef, useCallback } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import { SearchInput, debounce } from '@ecommerce/shared'
import { Icon } from '../Icon/Icon'

export interface SearchBoxProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeydown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onClear?: () => void
  value?: string
  className?: string
  inputClassName?: string
  searchIcon?: () => JSX.Element
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent) => void
  refine?: (e: string) => void
  currentRefinement?: string
  refineOnEnter?: boolean
}

export function SearchBox({
  onFocus,
  onBlur,
  onChange,
  onKeydown,
  onClear,
  value,
  className,
  inputClassName,
  searchIcon,
  refine,
  refineOnEnter,
}: SearchBoxProps) {
  const debouncedRefine = useRef(refine ? debounce(refine, 300) : null)
  const clearInput = useCallback(() => {
    if (onClear) onClear()
    if (refine) refine('')
  }, [onClear, refine])

  return (
    <SearchInput
      className={className}
      inputClassName={inputClassName}
      placeholder="Busca algún producto"
      reset={clearInput}
      onChange={(e) => {
        if (onChange) onChange(e)
        if (debouncedRefine.current) {
          debouncedRefine.current(e.target.value)
        }
      }}
      CustomSearchIcon={searchIcon}
      CustomCloseIcon={() => <Icon iconId="close" id="search-close-icon" />}
      value={value}
      hasText={!!value}
      keyDownHandler={(e) => {
        if (onKeydown) onKeydown(e)
        if (e.key === 'Enter' && debouncedRefine.current && refineOnEnter) {
          debouncedRefine.current(e.currentTarget.value)
        }
      }}
      onFocus={onFocus}
      onBlur={(e) => {
        if (e.relatedTarget) {
          const { id } = e.relatedTarget as Element
          if (id && ['search-close-button', 'search-close-icon'].includes(id)) {
            clearInput()
          }
        }
        if (onBlur) onBlur(e)
      }}
    />
  )
}

export const AlgoliaSearchBox = connectSearchBox(SearchBox)

export const getSearchBox = (useAlgoliaSearch?: boolean) => (useAlgoliaSearch ? AlgoliaSearchBox : SearchBox)
