import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { Icon, IconProps } from '../Icon/Icon'

const cssPrefix = `MenuTile__`

export const tileBoxStyles = css`
  border-bottom: 2px solid ${(props) => props.theme.colors.dark10};
  display: flex;
  cursor: pointer;
  overflow: hidden;
  padding: 15px 0;
  justify-content: space-between;
  align-items: center;
`

const Tile = styled.div`
  ${tileBoxStyles}
  div {
    display: flex;
  }
  .${cssPrefix} {
    &tile-text {
      font-size: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 15px;
    }
  }
`
export interface MenuTileProps {
  iconId: IconProps['iconId']
  text: string
  actionType?: string
  slug?: string
}

export const MenuTile = (props: MenuTileProps & HTMLAttributes<HTMLDivElement>) => (
  <Tile {...props}>
    <div>
      <Icon iconId={props.iconId} size="25" className={`${cssPrefix}icon`} />
      <span className={`${cssPrefix}tile-text`}>{props.text}</span>
    </div>
    {props.actionType === 'category' && <Icon iconId="chevron_right" size="25" className={`${cssPrefix}icon`} />}
  </Tile>
)
