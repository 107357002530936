import React from 'react'
import styled from 'styled-components'
import { breakpoints, toCssPrefix } from '@ecommerce/shared'
import { CategoryBadge, CategoryBadgeProps } from './CategoryBadge'

const { cssPrefix, toPrefix } = toCssPrefix('CategoryBadgeList__')

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 110px);
  padding: 30px 10px;
  justify-content: center;
  @media (${breakpoints.desktop.min}) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px 10px 10px 10px;
    .${cssPrefix} {
      &item {
        margin: 0;
      }
    }
  }
`

export interface CategoryBadgeListProps {
  items: CategoryBadgeProps[]
}

export function CategoryBadgeList({ items }: CategoryBadgeListProps) {
  return (
    <Wrapper>
      {items
        .filter((item) => item.label || item.imageUrl)
        .map((item) => (
          <CategoryBadge
            imageUrl={item.imageUrl}
            label={item.label}
            onClick={item.onClick}
            className={toPrefix('item')}
            key={`${item.label}`}
          />
        ))}
    </Wrapper>
  )
}
