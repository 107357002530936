/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useRef, useState } from 'react'
import loadable from '@loadable/component'
import { navigate } from '@reach/router'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import {
  breakpoints,
  useResolution,
  useShoppingCart,
  ZIndex,
  hasScrollbarStyles,
  getAuth,
  sendDataToGTM,
  sendProductsToGTM,
  sendRemoveProductToGTM,
  useLocation,
  getOrderId,
  ProductCart as CartItem,
  useAuth,
  getCartId,
  setOrderId,
  SignInReturn,
  AuthFormType,
  AuthWidget,
  lineItemsToCartState,
  useFirestore,
  prepareOrderForCheckout,
  CLOrder,
  getStoredCity,
  log,
  toCurrencyNumber,
  showToast,
  ProductCart as CartItemType,
  updateGuest,
} from '@ecommerce/shared'
import { City } from '@ecommerce/shared/src/services/Location/cities'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { IconTrash, IconClose } from '../Icons'
import secrets from '../../config/secrets'
import { useCartStockErrorHandler } from '../../utils/errors'
import { sendMessageToSentry, ErrorLevel, ErrorSource } from '../../utils/sentry'
import { removeProductsFromState, getByhashWithPromotions } from './utils'
import useAuthWidget from '../../hooks/useAuthWidget'
import ConfirmModal from './ConfirmModal'
import ProductCart from './ProductCart'
import Summary from './Summary'
import GiftProducts from './GiftProducts'
import ConfirmationAlert from '../ConfirmationAlert'
import EmptyCart from './EmptyCart'

const loadableOptions = {
  ssr: false,
}

const OutOfStockProducts = loadable(() => import('./OutOfStockProducts'), loadableOptions)

type OrderMetadata = CLOrder['attributes']['metadata']
type PartialMetadata = Partial<OrderMetadata>

const ModalBackground = loadable(() => import('@ecommerce/shared'), {
  resolveComponent: (components) => components.ModalBackground,
  ...loadableOptions,
})

const ContainerCart = styled(motion.div)<{ isOpen?: boolean }>`
  display: flex;
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: ${ZIndex.medium};
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.light};

  .ShoppingCart__confirmation {
    &-alert {
      margin: 0;
      padding-top: 13px;
      &-background {
        position: absolute;
        align-items: center;
      }
    }
  }

  @media (${breakpoints.phoneLandscape.min}) {
    width: 375px;
  }
`

const Items = styled.div`
  width: 100%;
  position: initial;
  padding-top: 72px;
  overflow-y: auto;
  ${hasScrollbarStyles}
`

const HeaderClose = styled(motion.div)`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 72px;
  background-color: ${(props) => props.theme.colors.primary};
  box-shadow: ${(props) => props.theme.boxShadow.lvlOne};
  position: fixed;
  z-index: ${ZIndex.medium};
  top: 0;

  span {
    height: 25px;
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    color: ${(props) => props.theme.colors.white};
    margin: 0 auto;
  }

  @media (${breakpoints.phoneLandscape.min}) {
    width: 375px;
  }
`

const ButtonClose = styled(motion.button)`
  position: fixed;
  right: 15px;
  top: 20px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  width: 34px;
  height: 24px;
  z-index: ${ZIndex.medium};
  -webkit-tap-highlight-color: transparent;

  svg {
    fill: ${(props) => props.theme.colors.white};
  }
`

const ContainerWithProducts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: ${(props) => props.theme.boxShadow.lvlOne};
`

const TotalProduct = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  height: 72px;

  span {
    font-size: 14px;
  }
  .shopping-cart-product {
    &-clear {
      color: ${(props) => props.theme.colors.primary};
    }
  }
  .shopping-cart-product-clear {
    color: ${({ theme }) => theme.colors.error};
  }
`

const ButtonTrash = styled(motion.div)`
  background-color: transparent;
  outline-color: transparent;
  outline-style: none;
  outline: none;

  &:focus {
    outline: 0;
    border: none;
    outline-style: none;
    -moz-outline-style: none;
  }
  svg {
    fill: ${({ theme }) => theme.colors.error};
    object-fit: contain;
  }
`

const TrashProduct = styled(motion.div)`
  display: flex;
  align-items: center;
  width: 110px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`

const WrapperDetailCart = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 300px;
`

interface CartProps {
  isOpenCart: boolean
  onCloseCart: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  currentCity: City
}

const ShoppingCart = (props: CartProps) => {
  const resolution = useResolution()
  const { isOpenCart = false, onCloseCart, currentCity } = props
  const [loadingPrice, setLoadingPrice] = useState(false)

  const { pushCartStateToFirestore } = useFirestore()
  const { executeRecaptcha } = useGoogleReCaptcha()
  function animateCar() {
    if (isOpenCart) {
      if (resolution.isMobile) {
        return {
          x: [0, 0],
        }
      }
      return {
        x: [400, 0],
      }
    }
    return {
      x: [0, 0],
    }
  }

  const shoppingCart = useShoppingCart()
  const { state, empty: onDeleteAll, removeProduct } = shoppingCart

  function onRemoveAllProducts() {
    sendRemoveProductToGTM(Object.values(state.byHash), isBO)
    return onDeleteAll()
  }

  const [cartState, setCartState] = useState(state)
  const [skusToInject, setSkusToInject] = useState<CartItemType[]>([])

  const [giftLineItems, setGiftLineItems] = useState<Array<{ quantity: number; sku: number }>>()
  const { byHash, globalTotal, globalQuantity, promotions } = cartState

  const authFormsProps = {
    signup: {
      title: 'Crear Cuenta',
      onFinished: () => sendDataToGTM({ event: 'newAccount' }),
    },
    signin: {
      title: 'Iniciar Sesión',
      onFinished: (response?: SignInReturn) => onSignInFormSubmit(response),
      onSend: () => setLoading(true),
      onError: () => setLoading(false),
    },
    guest: {
      title: 'Continuar como invitado',
      onFinished: (metadata?: PartialMetadata, email?: string) => onFormSubmit(metadata, email, true),
      currentCity,
      willCheckout: true,
      onSend: () => setLoading(true),
    },
  }
  const {
    showWidget,
    closeWidget,
    state: { type: authWidgetType, showWidget: showAuthForm },
    getProps,
  } = useAuthWidget()

  function showAuthWidget(type: AuthFormType) {
    showWidget(type)
  }

  const { state: authState } = useAuth()
  const [summaryIsLoading, setSummaryIsLoading] = useState(false)

  const customerEmail = useRef(authState.email)

  useEffect(() => {
    if (authState.email) customerEmail.current = authState.email
  }, [authState])

  const setLoading = (bool: boolean) => {
    setSummaryIsLoading(bool)
  }

  const [outOfStockProducts, setOutOfStockProducts] = useState<CartItem[]>([])
  const hasOutOfStockProducts = outOfStockProducts.length !== 0

  useEffect(() => {
    const stateProducts = removeProductsFromState(outOfStockProducts, state.byHash)

    setCartState({ ...state, byHash: stateProducts })
  }, [state])

  const onDeleteOutOfStockProducts = () => {
    sendRemoveProductToGTM(outOfStockProducts, isBO)
    outOfStockProducts.forEach((p) => removeProduct(p.skuCode))
    setOutOfStockProducts([])
  }

  const setNewCartState = (products: CartItem[]) => {
    const newCartState = { ...cartState }
    const newCartProducts = removeProductsFromState(products, cartState.byHash)

    newCartState.byHash = newCartProducts

    setCartState(newCartState)
    setOutOfStockProducts(products)
  }

  const {
    textByCountry,
    isBolivia,
    state: { country },
  } = useLocation()
  const isBO = isBolivia()

  const prepareOrderForCheckoutHandler = async (metadata: PartialMetadata = {}, userEmail?: string) => {
    try {
      const startTime = performance.now()
      const email = userEmail ?? customerEmail.current

      if (!email || email === '') {
        throw new Error(`on prepareOrderForCheckout: customerEmail is required, recieved ${customerEmail}`)
      }

      const { hasStockError, outOfStock, skus, order } = await prepareOrderForCheckout({
        lineItems: [...getByhashWithPromotions(byHash, promotions), ...skusToInject],
        country,
        customerEmail: email,
        orderMetadata: metadata,
        currentCity,
      })

      const outOfStockSkuCodes = outOfStock?.map((code) => `${code}`)

      const {
        id,
        attributes: { coupon_code },
      } = order

      if (hasStockError && outOfStockSkuCodes) {
        setNewCartState(Object.values(byHash).filter((item) => outOfStockSkuCodes.includes(`${item.skuCode}`)))

        return { success: false }
      }

      const newCartState = { ...lineItemsToCartState(skus), couponCode: coupon_code, orderId: id }
      const cartId = getCartId()

      if (!cartId) throw new Error('No FS cart available')

      pushCartStateToFirestore(cartId, newCartState)
      setOrderId(id)

      log.trace(`prepareForCheckoutHandler laster ${(performance.now() - startTime) / 1000}s`)
      return { success: true }
    } catch (e) {
      log.error(e)
      const error = e.response?.data
      const cartId = getCartId()
      const city = getStoredCity()

      sendMessageToSentry({
        page: 'global - shoppingCart:',
        message: `On prepareOrderForCheckout: ${error?.message || e?.message}`,
        source: ErrorSource.BFF,
        level: error?.Severity ?? ErrorLevel.Error,
        metadata: {
          orderId: getOrderId(),
          cartId,
          city,
          cartState,
          customerEmail,
          params: { metadata, userEmail },
          error,
        },
      })

      throw e
    }
  }

  async function onFormSubmit(orderMetadata?: PartialMetadata, email?: string, isGuest?: boolean) {
    try {
      setLoading(true)
      const validationResult = await prepareOrderForCheckoutHandler(orderMetadata, email)

      if (!validationResult.success) {
        return setLoading(false)
      }

      if (isBO && isGuest) {
        if (!executeRecaptcha) throw Error('cannot execute Recaptcha')
        const reCaptchaToken = await executeRecaptcha('signup')

        await updateGuest({
          firstName: orderMetadata?.firstName,
          lastName: orderMetadata?.lastName,
          rut: orderMetadata?.dni,
          email,
          billingName: orderMetadata?.billingName,
          documentType: orderMetadata?.documentType,
          dniComplement: orderMetadata?.dniComplement,
          reCaptchaToken,
          birthdate: orderMetadata?.birthdate,
          city: currentCity.id,
        })
      }

      await navigate(`/${currentCity.slug}/checkout`, {
        state: {
          referrer: window.location.pathname,
        },
      })
    } catch (e) {
      setLoading(false)

      showToast({ title: 'Error de Conexión', content: 'Por favor intenta nuevamente.', type: 'error' })

      throw e
    }
  }

  async function onSignInFormSubmit(response?: SignInReturn) {
    try {
      setLoading(true)
      if (!response?.data) throw new Error('onSignInFormSubmit: no response data')

      const {
        data: { firstName, lastName, dni, birthdate },
      } = response

      const cartId = getCartId()
      if (!cartId) throw new Error(`Invalid value: cartId is ${cartId}`)

      const orderMetadata = {
        firstName,
        lastName,
        dni,
        birthdate,
        cartId,
      }

      await onFormSubmit(orderMetadata)
    } catch (e) {
      setLoading(false)
      showToast({ title: 'Error de Conexión', content: 'Por favor intenta nuevamente.', type: 'error' })
      sendMessageToSentry({
        page: `Global - Shopping Cart`,
        message: `Go to Checkout: on SignIn error`,
        source: ErrorSource.CLayer,
        level: ErrorLevel.Error,
        metadata: { message: e?.message, error: { ...e }, response, authState },
      })
    }
  }

  const skus = Object.keys(byHash)
  const isSubtotalValidated = globalTotal >= Number(secrets.MIN_RAW_TOTAL)

  const totalProductRecyclable = Object.values(byHash).reduce(
    (total, product) => (product.recyclable && !product.isBundle ? total + product.quantity : total),
    0,
  )

  async function onSubmit() {
    if (isSubtotalValidated && !hasOutOfStockProducts) {
      if (!getAuth()) {
        showAuthWidget(AuthFormType.GUEST)
      } else onFormSubmit()
    }

    setIsOpenConfirmModal(false)
  }
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)
  const [isOpenConfirmAlert, setIsOpenConfirmAlert] = useState(false)

  function onConfirmSubmit() {
    if (totalProductRecyclable > 0) {
      setIsOpenConfirmAlert(true)
    } else onSubmit()
  }

  const textConfirm = `Llevas ${totalProductRecyclable} productos que NO incluyen precio por ${textByCountry(
    'envase',
    'botella',
  )}. ¿Confirma que tienes ${textByCountry('los envases', 'las botellas')} retornables para intercambiar?`

  useEffect(() => {
    if (isOpenCart) {
      sendProductsToGTM({
        event: 'cart',
        products: Object.values(byHash).map((product) => {
          return { ...product, price: toCurrencyNumber(product.price, isBO) }
        }),
      })
    }
  }, [isOpenCart])

  const stockErrorHandler = useCartStockErrorHandler()

  const minSubtotal = Number(secrets.MIN_RAW_TOTAL)
  const isSubtotalValid = globalTotal >= minSubtotal

  useEffect(() => {
    setGiftLineItems(
      state?.giftLineItems?.map(({ sku, discountQuantity = 0 }) => ({
        sku: Number(sku),
        quantity: discountQuantity,
      })) || [],
    )
  }, [state.giftLineItems])

  const summaryIsDisabled =
    hasOutOfStockProducts || summaryIsLoading || (!!giftLineItems && giftLineItems.length > 0 && loadingPrice)

  return (
    <ContainerCart isOpen={isOpenCart} animate={animateCar()} transition={{ duration: resolution.isMobile ? 0 : 0.2 }}>
      {isOpenConfirmModal && (
        <ModalBackground>
          <ConfirmModal
            closeModal={() => setIsOpenConfirmModal(false)}
            onDeleteAll={onRemoveAllProducts}
            image=""
            message=""
          />
        </ModalBackground>
      )}
      {showAuthForm && authWidgetType ? (
        <AuthWidget
          showWidget={showAuthWidget}
          closeWidget={closeWidget}
          title={authFormsProps[authWidgetType].title}
          formSettings={getProps(authWidgetType, authFormsProps)}
          onClose={closeWidget}
        />
      ) : null}
      {isOpenConfirmAlert && (
        <ConfirmationAlert
          className="ShoppingCart__confirmation-alert"
          backgroundClassName="ShoppingCart__confirmation-alert-background"
          onClose={() => setIsOpenConfirmAlert(false)}
          onBlur={() => setIsOpenConfirmAlert(false)}
          onConfirm={() => {
            setIsOpenConfirmAlert(false)
            onSubmit()
          }}
          confirmButtonText="Confirmar"
          text={textConfirm}
        />
      )}
      <HeaderClose>
        <span>Carro de compras</span>
      </HeaderClose>
      <ButtonClose whileTap={{ scale: 0.9 }} onClick={onCloseCart}>
        <IconClose />
      </ButtonClose>
      <Items>
        {skus.length === 0 && outOfStockProducts.length === 0 ? (
          <EmptyCart
            stockLocation={currentCity.commerceLayer.stockLocation.name}
            slugLocation={currentCity.slug}
            stockErrorHandler={stockErrorHandler}
          />
        ) : (
          <ContainerWithProducts>
            <TotalProduct className="shopping-cart-product">
              <span className="shopping-cart-product-total">
                Total de productos:
                {` ${globalQuantity}`}
              </span>
              <TrashProduct
                data-testid="trash-cart"
                whileTap={{ scale: 0.95 }}
                onClick={() => setIsOpenConfirmModal(true)}
              >
                <span className="shopping-cart-product-clear">Vaciar carro</span>
                <ButtonTrash>
                  <IconTrash />
                </ButtonTrash>
              </TrashProduct>
            </TotalProduct>

            <OutOfStockProducts onDelete={onDeleteOutOfStockProducts} products={outOfStockProducts} isBO={isBO} />

            <WrapperDetailCart>
              {skus.map((skuCode) => (
                <ProductCart
                  presale={Number(skuCode) === secrets.JW_PRESALE_SKU}
                  key={skuCode}
                  product={byHash[skuCode]}
                  promotion={promotions && promotions[skuCode]}
                  setLoadingPrice={setLoadingPrice}
                  loadingPrice={loadingPrice}
                />
              ))}
              {giftLineItems?.length && isSubtotalValid ? (
                <GiftProducts
                  key={giftLineItems?.length}
                  onFetchProducts={isSubtotalValid ? setSkusToInject : undefined}
                  slugLocation={currentCity.slug}
                  items={giftLineItems}
                  loading={loadingPrice}
                />
              ) : null}
            </WrapperDetailCart>
          </ContainerWithProducts>
        )}
      </Items>

      {skus.length !== 0 || outOfStockProducts.length !== 0 ? (
        <Summary
          isDisabled={summaryIsDisabled}
          isLoading={summaryIsLoading}
          onSubmit={onConfirmSubmit}
          errorMessage={hasOutOfStockProducts ? 'Elimina los productos sin stock para continuar' : undefined}
          loadingPrice={loadingPrice}
          promotions={promotions}
        />
      ) : null}
    </ContainerCart>
  )
}

export default ShoppingCart
