import { graphql, useStaticQuery } from 'gatsby'

type EmptyCartSlider = {
  title: string
  actionSlug: string
  skus: string[]
}

const useSlider = (): EmptyCartSlider => {
  const data = useStaticQuery(graphql`
    query EmptyShoppingCart {
      allContentfulGlobal {
        edges {
          node {
            id
            emptyCartList {
              actionSlug
              title
              items {
                ... on ContentfulBundle {
                  sku
                }
                ... on ContentfulProduct {
                  sku
                }
              }
            }
          }
        }
      }
    }
  `)

  const { title, actionSlug } = data.allContentfulGlobal.edges[0].node.emptyCartList
  const skus = data.allContentfulGlobal.edges[0].node.emptyCartList.items.map(({ sku }: { sku: string }) => sku)

  return { title, actionSlug, skus }
}

export default useSlider
