import React from 'react'
import { navigate } from 'gatsby'
import { useLocation, sendDataToGTM, capitalize } from '@ecommerce/shared'
import { FlatLocationProductCategory } from '../types/PgPages'
import { CategoryBadgeList } from '../components/Category'
import { MainNavbarProps } from '../components/NavBar/MainNavbar'

export interface UseMobileNavbarMenuProps {
  categories?: FlatLocationProductCategory[]
  citySlug: string
  isMoment?: boolean
  disableControls?: boolean
}

export const useMobileNavbarMenu = ({ categories, citySlug, isMoment, disableControls }: UseMobileNavbarMenuProps) => {
  const { textByCountry } = useLocation()
  return [
    ...(isMoment
      ? [
          {
            iconId: 'home',
            label: 'Inicio',
            onClick: disableControls ? '' : () => navigate(`/${citySlug}`),
          },
        ]
      : []),
    {
      iconId: 'grid',
      label: 'Categorías',
      children: (
        <CategoryBadgeList
          items={
            categories?.map((cat) => ({
              imageUrl: cat.image,
              label: cat.name,
              onClick: () => {
                if (disableControls) return
                sendDataToGTM({ event: 'categoryClick', categoryName: cat.name })
                navigate(cat.slug.startsWith('/') ? `/${citySlug}${cat.slug}` : `/${citySlug}/category/${cat.slug}`)
              },
            })) ?? []
          }
        />
      ),
      isActive: typeof window !== 'undefined' && window.location.href.includes(`${citySlug}/category`),
    },
    {
      iconId: 'Promociones',
      label: textByCountry('Promociones', 'Ofertas'),
      onClick: () => {
        if (disableControls) return
        sendDataToGTM({ event: 'navPromotionClick' })
        navigate(`/${citySlug}/promotion`)
      },
      isActive:
        !disableControls && typeof window !== 'undefined' && window.location.href.includes(`${citySlug}/promotion`),
    },
  ] as MainNavbarProps['tabs']
}
