import React from 'react'
import styled from 'styled-components'
import {
  breakpoints,
  Button,
  MouseEventButton,
  toNumber,
  useShoppingCart,
  useLocation,
  Promotions,
} from '@ecommerce/shared'
import secrets from '../../config/secrets'
import DotsLoader from '../DotsLoader'

const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.medium75};
  position: fixed;
  bottom: 0;
  min-height: 160px;
  width: 100%;
  padding: 20px;

  .Summary__box-mobile + .Summary__box-mobile {
    margin-top: 10px;
  }

  .Summary__divider {
    height: 1px;
    background-color: ${(props) => props.theme.colors.medium};
    margin: 10px 0;
  }

  .Summary__ {
    &box,
    &box-mobile {
      background-color: white;
      border-radius: 4px;
      padding: 10px 18px;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      box-shadow: ${(props) => props.theme.boxShadow.lvlOne};

      &-title {
        color: ${(props) => props.theme.colors.dark};

        &-danger {
          color: ${(props) => props.theme.colors.error};
        }
        &-success {
          color: ${(props) => props.theme.colors.success};
        }
      }
      &-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-gap: 5px;
        padding: 10px 0;
        color: ${(props) => props.theme.colors.dark};

        span:nth-child(even) {
          text-align: right;
        }

        span:nth-child(odd) {
          text-align: left;
        }

        &-discount {
          color: #33ba00;
        }

        &-total {
          font-size: 18px;
          font-weight: bold;
          color: #030f1c;
        }
        &-loading {
          display: flex;
          justify-content: end;
          align-items: end;
          margin-right: 15px;
        }
      }

      &-footer {
        padding: 10px 0;
        justify-content: center;
        display: flex;

        &-button {
          width: 70%;
        }
      }

      &-mobile-footer {
        display: grid;
        grid-template-columns: 2fr 1fr;

        &-subtotal {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          span {
            text-align: right;
            font-weight: bold;
            font-size: 12px;
            color: ${(props) => props.theme.colors.dark};
          }

          span:nth-child(2) {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: -0.25px;
            line-height: 0.8;
            color: ${(props) => props.theme.colors.black};
          }
        }
      }
    }

    &box {
      display: none;
    }
  }

  @media (${breakpoints.phoneLandscape.min}) {
    width: 375px;
    .Summary__ {
      &box {
        display: block;

        &-title {
          padding: 10px 0;
        }
      }

      &box-mobile {
        display: none;
      }
    }
  }
`
interface Props {
  onSubmit: (e: MouseEventButton) => void
  isLoading: boolean
  isDisabled: boolean
  errorMessage?: string
  loadingPrice: boolean
  promotions?: Promotions
}

const Summary = (props: Props) => {
  const { onSubmit, isLoading, isDisabled, errorMessage, loadingPrice, promotions } = props

  const {
    state: { globalTotal, shippingCost, globalTotalPromotion },
  } = useShoppingCart()

  const { toCurrency, textByCountry } = useLocation()

  const total = globalTotal + shippingCost - (globalTotalPromotion ?? 0)
  const minSubtotal = Number(secrets.MIN_RAW_TOTAL)
  const isSubtotalValid = globalTotal >= minSubtotal
  const canCheckout = isSubtotalValid && !isDisabled && !isLoading

  // Country settings
  const payButtonText = textByCountry('Ir a pagar', 'Realizar pedido')

  const getCartMessage = () => {
    if (isDisabled) return errorMessage

    if (!isSubtotalValid) return `Recuerda que el monto mínimo de compra es de ${toCurrency(minSubtotal)}.`

    return 'Resumen de tu cuenta'
  }

  return (
    <Wrapper>
      <div className="Summary__box">
        <span className={`Summary__box-title${canCheckout ? '' : '-danger'}`}>{getCartMessage()}</span>
        <div className="Summary__divider" />
        <div className="Summary__box-content">
          <span>Sub-total</span>
          <span data-testid="subtotal-cart">{toCurrency(globalTotal)}</span>
          <span>Despacho</span>
          <span>{toCurrency(shippingCost)}</span>
          <span className="Summary__box-content-total">Total</span>
          {!loadingPrice || Object.values(promotions ?? {}).length === 0 ? (
            <span data-testid="total-cart" className="Summary__box-content-total">
              {toCurrency(total)}
            </span>
          ) : (
            <div className="Summary__box-content-loading">
              <DotsLoader />
            </div>
          )}
        </div>
        <div className="Summary__divider" />
        <div className="Summary__box-footer">
          <Button
            data-testid="go-pay"
            className="Summary__box-footer-button"
            onClick={canCheckout ? onSubmit : () => null}
            isLoading={isLoading}
            isDisabled={!canCheckout}
          >
            {payButtonText}
          </Button>
        </div>
      </div>

      <div className="Summary__box-mobile">
        <span className={`Summary__box-mobile-title${shippingCost !== 0 ? '' : '-success'}`}>
          {shippingCost === 0
            ? `Compra despacho costo ${toCurrency(shippingCost)}`
            : `Se agregará un costo por despacho de $${toNumber(shippingCost)}`}
        </span>
      </div>

      <div className="Summary__box-mobile">
        {!isSubtotalValid && (
          <>
            <p className="Summary__box-mobile-title-danger">
              {`El subtotal debe ser mayor a ${toCurrency(minSubtotal)}`}
            </p>
            <div className="Summary__divider" />
          </>
        )}
        <div className="Summary__box-mobile-footer">
          <Button onClick={canCheckout ? onSubmit : () => null} isLoading={isLoading} isDisabled={!canCheckout}>
            {payButtonText}
          </Button>
          <div className="Summary__box-mobile-footer-subtotal">
            <span>Sub-total</span>
            <span>{`${toCurrency(globalTotal)}`}</span>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Summary
