import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

const cssPrefix = `CategoryTile__`

export const tileBoxStyles = css`
  border-bottom: 2px solid ${(props) => props.theme.colors.dark10};
  display: flex;
  cursor: pointer;
  overflow: hidden;
  padding: 15px 0;
  justify-content: space-between;
  align-items: center;
`

const Tile = styled.div`
  ${tileBoxStyles}
  div {
    display: flex;
    align-items: center;
  }
  .${cssPrefix} {
    &image {
      width: 55px;
      height: 55px;
    }
    &tile-text {
      font-size: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 15px;
    }
  }
`
export interface CategoryTileProps {
  image: string
  mainCategoryName: string
  name: string
  slug: string
}

export const CategoryTile = (props: CategoryTileProps & HTMLAttributes<HTMLDivElement>) => (
  <Tile {...props}>
    <div>
      <img src={props.image} alt="categoria" className={`${cssPrefix}image`} />
      <span className={`${cssPrefix}tile-text`}>{props.name}</span>
    </div>
  </Tile>
)
