import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { toCssPrefix, Anchor } from '@ecommerce/shared'

const { cssPrefix, toPrefix } = toCssPrefix('CategoryBadge__')

const Wrapper = styled.div`
  .${cssPrefix} {
    &container {
      width: 110px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &image {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
    }
    &label {
      color: ${({ theme }) => theme.colors.dark};
      font-weight: bold;
      font-size: 14px;
      margin: 8px 0;
    }
  }
`

export interface CategoryBadgeProps extends HTMLAttributes<HTMLDivElement> {
  imageUrl?: string
  label?: string
  onClick?: (e: React.MouseEvent) => void
}

export const CategoryBadge = ({ imageUrl, label, onClick, className }: CategoryBadgeProps) => {
  return (
    <Wrapper className={className}>
      <Anchor className={toPrefix('container')} onClick={onClick}>
        <img className={toPrefix('image')} src={imageUrl} alt={label} />
        <span className={toPrefix('label')}>{label}</span>
      </Anchor>
    </Wrapper>
  )
}
