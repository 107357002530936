import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import loadable from '@loadable/component'
import { navigate } from 'gatsby'
import { Loading, Product, CartStockErrorHandler, slugify } from '@ecommerce/shared'
import { getNavigatableProductsBySkus } from '../../utils/algolia'
import { EmptyCartIcon } from '../Icons'
import useSlider from './useEmptyCartList'

const SliderWrapper = loadable(() => import('../SliderWrapper'), { ssr: false })
const ProductListSlider = loadable(() => import('../ProductListSlider'), {
  ssr: false,
  resolveComponent: (components) => components.ProductListSlider,
})

const ContainerEmptyCart = styled.div`
  width: 100%;

  span {
    font-size: 14px;
    color: ${(props) => props.theme.colors.black};
  }

  .shopping-cart-empty-product {
    &-cart {
      width: 226px;
      font-weight: bold;
      margin-top: 2px;
    }
    &-interest {
      width: 257px;
      font-weight: 500;
      margin-top: 15px;
    }
  }
`

const EmptyInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 207px;
`

const BoxEmptyIcon = styled.div`
  background-color: transparent;
  outline-color: transparent;
  outline-style: none;
  outline: none;
  margin-top: 19px;
  &:focus {
    outline: 0;
    border: none;
    outline-style: none;
    -moz-outline-style: none;
  }
  svg {
    width: 119px;
    height: 119px;
    fill: ${(props) => props.theme.colors.primary};
    object-fit: contain;
  }
`

const WrapperProductList = styled.div`
  padding: 0 1px 0 15px;
  min-height: 275px;
  .product-list {
    padding: 22px 1px 11px;
    > div > div {
      padding-left: 10px;
    }

    &-scroll {
      column-gap: 12px;
    }

    &-header {
      padding-left: 10px;
    }
  }

  .EmptyCartSlider {
    padding: 22px 0;

    .bg {
      &-first-section {
        height: 15%;
      }

      &-second-section {
        height: 310px;
      }
    }
  }
`

interface Props {
  slugLocation: string
  stockLocation: string
  stockErrorHandler?: CartStockErrorHandler
}

const EmptyCart = (props: Props) => {
  const { slugLocation, stockLocation, stockErrorHandler } = props
  const [products, setProducts] = useState<Product[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const { title, actionSlug, skus } = useSlider()

  useEffect(() => {
    setIsLoading(true)
    getNavigatableProductsBySkus(slugify(stockLocation), skus, { hitsPerPage: 100 }, undefined, 'Empty Cart')
      .then((result) => setProducts(result))
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <ContainerEmptyCart className="shopping-cart-empty-product">
      <EmptyInfo>
        <BoxEmptyIcon>
          <EmptyCartIcon />
        </BoxEmptyIcon>
        <span className="shopping-cart-empty-product-cart">Tu carro está vacio</span>
        {products && products.length !== 0 && !isLoading ? (
          <span className="shopping-cart-empty-product-interest">Estos productos te pueden interesar</span>
        ) : null}
      </EmptyInfo>
      <WrapperProductList>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {products && products.length !== 0 ? (
              <SliderWrapper
                className="EmptyCartSlider"
                hideNavigation={!actionSlug}
                title={title}
                bg="#fff"
                bgBottom="rgb(232,235,247)"
                bgTop="#fff"
                color="rgb(3,15,28)"
              >
                <ProductListSlider
                  useControls
                  onShowMoreClick={() => navigate(`/${slugLocation}/${actionSlug}`)}
                  stockErrorHandler={stockErrorHandler}
                  type="small"
                  products={products}
                  cardsGapMobile={20}
                  cardsGapDesktop={40}
                  cardWidthDesktop={190}
                  cardWidthMobile={190}
                />
              </SliderWrapper>
            ) : null}
          </>
        )}
      </WrapperProductList>
    </ContainerEmptyCart>
  )
}

export default EmptyCart
